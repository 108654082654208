export const LOGIN_MODAL_ID = 'LOGIN_DIALOG_ID';
export const SIGNUP_MODAL_ID = 'SIGNUP_DIALOG_ID';
export const CREATE_SERVICE_MODAL_ID = 'CREATE_SERVICE_DIALOG_ID';
export const REQUEST_SERVICE_MODAL_ID = 'REQUEST_SERVICE_MODAL_ID';
export const REQUEST_SERVICE_DONE_MODAL_ID = 'REQUEST_SERVICE_DONE_MODAL_ID';
export const REQUEST_SERVICE_FINISH_MODAL_ID =
  'REQUEST_SERVICE_FINISH_MODAL_ID';
export const SOCIAL_LOGIN_MODAL_ID = 'SOCIAL_LOGIN_MODAL_ID';
export const FORGOT_PASSWORD_MODAL_ID = 'FORGOT_PASSWORD_MODAL_ID';
export const RESET_PASSWORD_MODAL_ID = 'RESET_PASSWORD_MODAL_ID';

export const REQUEST_SERVICE_DELETE_CONFIRM_MODAL_ID =
  'REQUEST_SERVICE_DELETE_CONFIRM_MODAL_ID';
export const REQUEST_SERVICE_DISPUTE_CONFIRM_MODAL_ID =
  'REQUEST_SERVICE_DISPUTE_CONFIRM_MODAL_ID';

export const USER_DELETE_CONFIRM_MODAL_ID =
  'USER_DELETE_CONFIRM_MODAL_ID';
