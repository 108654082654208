import React from 'react';
import { withStyles, makeStyles, fade } from '@material-ui/core/styles';
import { Box, Button, Dialog, Typography, Grid } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FacebookIcon from '@material-ui/icons/Facebook';
import EmailIcon from '@material-ui/icons/Email';
import GoogleIcon from 'img/google_logo.svg';

import { loginToken } from 'actions/user';

import login_illustration from 'img/sign_in.svg';
import DialogTitle from 'components/Dialogs/DialogTitle';
import ConnectedDialog from 'components/ConnectedDialog';
import { SOCIAL_LOGIN_MODAL_ID, LOGIN_MODAL_ID } from 'consts/modalIds';
import { Login } from 'react-facebook';
import GoogleLogin from 'react-google-login';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useDialogStyles = makeStyles(theme => ({
  dialogImage: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
  },
}));

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      maxWidth: '100%',
      width: '500px',
    },
  },
  socialMediaButton: {
    margin: theme.spacing(1),
    width: 300,
    marginBottom: 20,
  },
  facebookBtn: {
    margin: theme.spacing(1),
    width: 300,
    marginBottom: 20,
    backgroundColor: theme.palette.facebook.main,
    color: theme.palette.facebook.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.facebook.dark,
    },
  },
  googleBtn: {
    margin: theme.spacing(1),
    width: 300,
    marginBottom: 20,
    backgroundColor: theme.palette.google.main,
    color: theme.palette.google.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.google.dark,
    },
    '& img': {
      height: 20,
      width: 20,
    }
  },
  introTextMargin: {
    marginLeft: 10,
  },
}));

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const ImageBox = withStyles(() => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
  },
}))(Box);

const SocialMediaLoginModal = ({ openModal, closeModal, loginToken }) => {
  const dialogStyles = useDialogStyles();
  const classes = useStyles();
  const history = useHistory();

  const handleClose = () => {
    closeModal(SOCIAL_LOGIN_MODAL_ID);
  };

  const handleFbResponse = async data => {
    const user = await loginToken('facebook', data.tokenDetail.accessToken);
    handleClose();
    if (!user.registration_completed) {
      history.push('/editProfile');
    }
  };

  const handleGoogleResponse = async data => {
    const user = await loginToken('google', data.accessToken);
    handleClose();
    if (!user.registration_completed) {
      history.push('/editProfile');
    }
  };

  const emailLoginClicked = () => {
    handleClose();
    openModal(LOGIN_MODAL_ID);
  };

  return (
    <ConnectedDialog
      id={SOCIAL_LOGIN_MODAL_ID}
      aria-labelledby="customized-dialog-title"
      maxWidth="sm"
    >
      <Grid container spacing={0}>
        <Grid item xs={12} md={4} sm={6}>
          <ImageBox p={2} bgcolor="background.default">
            <img
              src={login_illustration}
              className={dialogStyles.dialogImage}
            />
          </ImageBox>
        </Grid>
        <Grid item xs={12} md={8} sm={6}>
          <DialogContent>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              Најава
            </DialogTitle>
            <Typography gutterBottom className={classes.introTextMargin}>
              Внеси ги твоите податоци за најава и започни да ги користиш
              понудените услуги од членовите на Временската банка.
            </Typography>
            <Box component="div">
              <Login scope="email" onCompleted={handleFbResponse}>
                {({ loading, handleClick, error, data }) => (
                  <Button
                    variant="contained"
                    startIcon={<FacebookIcon />}
                    className={classes.facebookBtn}
                    onClick={handleClick}
                    disabled={loading}
                  >
                    Најава со Facebook
                  </Button>
                )}
              </Login>
            </Box>
            <Box>
              <GoogleLogin
                clientId={process.env.RAZZLE_GOOGLE_ID}
                cookiePolicy={'single_host_origin'}
                onSuccess={handleGoogleResponse}
                onFailure={handleGoogleResponse}
                render={({ onClick, disabled }) => (
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<img src={GoogleIcon} />}
                    className={classes.googleBtn}
                    onClick={onClick}
                    disabled={disabled}
                  >
                    Најава со Google
                  </Button>
                )}
              />
            </Box>
            <Box>
              <Button
                variant="contained"
                startIcon={<EmailIcon />}
                color="secondary"
                className={classes.socialMediaButton}
                onClick={emailLoginClicked}
              >
                Најава со E-mail
              </Button>
            </Box>
          </DialogContent>
        </Grid>
      </Grid>
    </ConnectedDialog>
  );
};

const mapDispatchToProps = {
  loginToken,
};

export default connect(null, mapDispatchToProps)(SocialMediaLoginModal);
