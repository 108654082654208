import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { login } from 'actions/user';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Button, Dialog, Typography, Grid } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { positions } from '@material-ui/system';
import DialogTitle from 'components/Dialogs/DialogTitle';
import TextField from 'components/Inputs/TextField';
import Checkbox from 'components/Inputs/Checkbox';
import ConnectedDialog from 'components/ConnectedDialog';
import { LOGIN_MODAL_ID, SIGNUP_MODAL_ID, FORGOT_PASSWORD_MODAL_ID } from 'consts/modalIds';
import ForgotPasswordModal from 'components/Users/ForgotPasswordModal';
import login_illustration from 'img/sign_in.svg';
import { useSnackbar } from 'notistack';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from 'react-router-dom';

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('Невалидна е-маил адреса')
    .required('Ова поле е задолжително'),
  password: Yup.string()
    .min(5, 'Внесете најмалку 5 знаци')
    .required('Ова поле е задолжително'),
  remember: Yup.bool().default(false),
});

const useDialogStyles = makeStyles(theme => ({
  dialogImage: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
  },
}));

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      maxWidth: '100%',
      width: '500px',
    },
  },
  introTextMargin: {
    marginLeft: 10,
  },
  link: {
    textDecoration: 'none'
  },
  title: {
    textTransform: 'inherit'
  }
}));

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const ImageBox = withStyles(() => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
  },
}))(Box);

const EmailLoginModal = ({ login, openModal, closeModal }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();

  const dialogStyles = useDialogStyles();
  const classes = useStyles();

  const closeLoginModal = () => {
    closeModal(LOGIN_MODAL_ID);
  };

  const openSignupModal = () => {
    closeLoginModal();
    openModal(SIGNUP_MODAL_ID);
  };

  const openForgotPasswordModal = () => {
    closeLoginModal();
    openModal(FORGOT_PASSWORD_MODAL_ID);
  }

  const loginUser = async values => {
    values.email = values.email.trim();
    try {
      const user = await login(values);
      enqueueSnackbar('Успешна најава', {
        variant: 'success',
      });
      closeLoginModal();
      if (!user.registration_completed) {
        history.push('/editProfile');
      }
    } catch (e) {
      enqueueSnackbar('Неуспешна најава. Обидете се повторно.', {
        variant: 'error',
      });
    }
  };

  return (
    <ConnectedDialog
      id={LOGIN_MODAL_ID}
      aria-labelledby="customized-dialog-title"
      maxWidth="md"
    >
      <Grid container spacing={0}>
        <Grid item xs={12} md={4} sm={6}>
          <ImageBox p={2} bgcolor="background.default">
            <img
              src={login_illustration}
              className={dialogStyles.dialogImage}
            />
          </ImageBox>
        </Grid>
        <Grid item xs={12} md={8} sm={6}>
          <DialogContent>
            <DialogTitle id="customized-dialog-title" onClose={closeLoginModal}>
              Најава
            </DialogTitle>
            <Typography gutterBottom className={classes.introTextMargin}>
              Внеси ги твоите податоци за најава и започни да ги користиш
              понудените услуги од членовите на Временската банка.
            </Typography>
            <Formik
              initialValues={{ email: '', password: '', remember: false }}
              validationSchema={LoginSchema}
              validateOnChange={false}
              onSubmit={loginUser}
            >
              {({ isSubmitting }) => (
                <Form className={classes.root} autoComplete="on">
                  <TextField
                    name="email"
                    id="outlined-basic"
                    label="Внеси е-маил адреса"
                    variant="outlined"
                  />
                  <br />
                  <TextField
                    type="password"
                    name="password"
                    id="outlined-basic"
                    label="Внеси лозинка"
                    variant="outlined"
                    fullWidth
                  />
                  <Link to="/services" className={classes.link}>
                    <Button className={classes.title} onClick={openForgotPasswordModal}>Заборавена лозинка? Кликни тука</Button>
                  </Link>
                  <Grid container spacing={3}>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Најава
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={openSignupModal}
                      >
                        Регистрација
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
            <ForgotPasswordModal />
          </DialogContent>
        </Grid>
      </Grid>
    </ConnectedDialog>
  );
};

const mapDispatchToProps = {
  login,
};

export default connect(null, mapDispatchToProps)(EmailLoginModal);
