import './client.css';
import React, { useEffect } from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ensureReady, After, getSerializedData } from '@jaredpalmer/after';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
import routes from './routes';
import configureStore from './store/configureStore';
import Layout from './Layout';
import { CssBaseline } from '@material-ui/core';

const preloadedState = getSerializedData('preloaded_state');
const store = configureStore(preloadedState);

const Main = ({ data }) => {
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Layout>
            <After data={data} routes={routes} store={store} />
          </Layout>
        </BrowserRouter>{' '}
      </ThemeProvider>
    </Provider>
  );
};

const renderApp = () => {
  ensureReady(routes).then(data => {
    return hydrate(<Main data={data} />, document.getElementById('root'));
  });
};

renderApp();

if (module.hot) {
  module.hot.accept('./routes', renderApp);
}
