import * as ServiceActions from 'actions/service';
import * as ServiceRequestActions from 'actions/serviceRequest';
import reducerWithActionMap from '../utils/reducerWithActionMap';

const initialState = {
  isAuthenticated: false,
  serviceRequestList: {
    isFetching: false,
    isFetched: false,
    listData: null,
  },
  serviceRequestDetails: {
    isFetching: false,
    isFetched: false,
    serviceRequestData: null,
  },
  serviceRequestComments: {
    isFetching: false,
    isFetched: false,
    listData: null,
  },
};

const setServiceRequestListFetching = fetching => state => ({
  ...state,
  serviceRequestList: {
    ...state.serviceRequestList,
    isFetching: fetching,
  },
});

const setServiceRequestList = (state, { payload: { listData } }) => ({
  ...state,
  serviceRequestList: {
    ...state.serviceRequestList,
    listData,
    isFetching: false,
    isFetched: true,
  },
});

const setServiceRequestFetching = fetching => state => ({
  ...state,
  serviceRequestDetails: {
    ...state.serviceRequestDetails,
    isFetching: fetching,
  },
});

const setServiceRequest = (state, { payload: { serviceRequestData } }) => ({
  ...state,
  serviceRequestDetails: {
    ...state.serviceRequestDetails,
    serviceRequestData,
    isFetching: false,
    isFetched: true,
  },
});

const setServiceRequestCommentsFetching = fetching => state => ({
  ...state,
  serviceRequestComments: {
    ...state.serviceRequestComments,
    isFetching: fetching,
  },
});

const setServiceRequestComments = (state, { payload: { listData } }) => ({
  ...state,
  serviceRequestComments: {
    ...state.serviceRequestComments,
    listData,
    isFetching: false,
    isFetched: true,
  },
});

const addServiceRequestComment = (state, { payload: { commentData } }) => {
  const comments = state.serviceRequestComments.listData;
  comments.rows.push(commentData);
  comments.count += 1;
  return {
    ...state,
    serviceRequestComments: {
      ...state.serviceRequestComments,
      listData: comments,
      isFetching: false,
      isFetched: true,
    },
  };
};

const actionMap = {
  [ServiceRequestActions.LIST_REQUEST]: setServiceRequestListFetching(true),
  [ServiceRequestActions.LIST_FAILURE]: setServiceRequestListFetching(false),
  [ServiceRequestActions.LIST_SUCCESS]: setServiceRequestList,
  [ServiceRequestActions.FETCH_REQUEST]: setServiceRequestFetching(true),
  [ServiceRequestActions.FETCH_FAILURE]: setServiceRequestFetching(false),
  [ServiceRequestActions.FETCH_SUCCESS]: setServiceRequest,
  [ServiceRequestActions.UPDATE_SUCCESS]: setServiceRequest,
  [ServiceRequestActions.FETCH_COMMENTS_REQUEST]: setServiceRequestCommentsFetching(
    true
  ),
  [ServiceRequestActions.FETCH_COMMENTS_FAILURE]: setServiceRequestCommentsFetching(
    false
  ),
  [ServiceRequestActions.FETCH_COMMENTS_SUCCESS]: setServiceRequestComments,
  [ServiceRequestActions.CREATE_COMMENT_SUCCESS]: addServiceRequestComment,
};

export default reducerWithActionMap(actionMap, initialState);
