import getAxiosInstance from 'config/http';
import { DELETE_SERVICE_FAILURE } from './service';

export const LOGIN_REQUEST = 'USER@LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'USER@LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'USER@LOGIN_FAILURE';
export const login = loginData => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: LOGIN_REQUEST,
  });
  try {
    const userData = await http.post('/auth/signin', loginData);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        userData: userData.data,
      },
    });
    return userData.data;
  } catch (e) {
    dispatch({
      type: LOGIN_FAILURE,
    });
    throw e;
  }
};

export const loginToken = (type, token) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: LOGIN_REQUEST,
  });
  try {
    const { data } = await http.get(`/auth/${type}/token`, {
      params: { access_token: token },
    });
    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        userData: data,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: LOGIN_FAILURE,
    });
    throw e;
  }
};

export const SIGNUP_REQUEST = 'USER@SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'USER@SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'USER@SIGNUP_FAILURE';
export const signup = loginData => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: SIGNUP_REQUEST,
  });
  try {
    const userData = await http.post('/auth/signup', loginData);
    dispatch({
      type: SIGNUP_SUCCESS,
      payload: {
        userData: userData.data,
      },
    });
  } catch (e) {
    dispatch({
      type: SIGNUP_FAILURE,
    });
    throw e;
  }
};

export const EDIT_PROFILE_REQUEST = 'USER@EDIT_PROFILE_REQUEST';
export const EDIT_PROFILE_SUCCESS = 'USER@EDIT_PROFILE_SUCCESS';
export const EDIT_PROFILE_FAILURE = 'USER@EDIT_PROFILE_FAILURE';
export const editProfile = (newData, completeProfile) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: EDIT_PROFILE_REQUEST,
  });
  try {
    let res;
    if (completeProfile) {
      res = await http.post('/auth/registration-complete', newData);
    } else {
      res = await http.put('/users/me', newData);
    }
    dispatch({
      type: EDIT_PROFILE_SUCCESS,
      payload: { userData: res.data },
    });
  } catch (e) {
    dispatch({
      type: EDIT_PROFILE_FAILURE,
    });
    throw e;
  }
};

export const FETCH_USER_DATA_REQUEST = 'USER@FETCH_USER_DATA_REQUEST';
export const FETCH_USER_DATA_SUCCESS = 'USER@FETCH_USER_DATA_SUCCESS';
export const FETCH_USER_DATA_FAILURE = 'USER@FETCH_USER_DATA_FAILURE';
export const fetchUserData = () => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_USER_DATA_REQUEST,
  });
  try {
    const { data } = await http.get('/users/me');
    dispatch({
      type: FETCH_USER_DATA_SUCCESS,
      payload: { userData: data },
    });
  } catch (e) {
    dispatch({
      type: FETCH_USER_DATA_FAILURE,
    });
    throw e;
  }
};

export const LIST_REQUEST = 'USER@LIST_REQUEST';
export const LIST_SUCCESS = 'USER@LIST_SUCCESS';
export const LIST_FAILURE = 'USER@LIST_FAILURE';
export const listUsers = () => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_REQUEST,
  });
  try {
    const { data } = await http.get('/users', {
      params: {
        limit: 200,
      },
    });
    dispatch({
      type: LIST_SUCCESS,
      payload: { listData: data },
    });
  } catch (e) {
    dispatch({
      type: LIST_FAILURE,
    });
    throw e;
  }
};

export const GET_USER_PROFILE_REQUEST = 'USER@GET_USER_PROFILE_REQUEST';
export const GET_USER_PROFILE_SUCCESS = 'USER@GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAILURE = 'USER@GET_USER_PROFILE_FAILURE';
export const getProfile = id => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: GET_USER_PROFILE_REQUEST,
  });
  try {
    const { data } = await http.get(`/users/${id}`);
    dispatch({
      type: GET_USER_PROFILE_SUCCESS,
      payload: { profile: data },
    });
    return data;
  } catch (e) {
    dispatch({
      type: GET_USER_PROFILE_FAILURE,
    });
    throw e;
  }
};

export const LOGOUT_REQUEST = 'USER@LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'USER@LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'USER@LOGOUT_FAILURE';
export const logout = () => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: LOGOUT_REQUEST,
  });
  try {
    await http.get(`/auth/signout`);
    dispatch({
      type: LOGOUT_SUCCESS,
    });
  } catch (e) {
    dispatch({
      type: LOGOUT_FAILURE,
    });
    throw e;
  }
};

export const verifyEmail = async token => {
  const http = getAxiosInstance();
  await http.get(`/auth/confirm`, { params: { token } });
};

export const FORGOT_PASSWORD_REQUEST = 'USER@FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'USER@FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'USER@FORGOT_PASSWORD_FAILURE';
export const forgot = values => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: FORGOT_PASSWORD_REQUEST,
  });
  try {
    const { email } = await http.post(`/auth/forgot`, values);
    dispatch({
      type: FORGOT_PASSWORD_SUCCESS,
      payload: { data: email },
    });
  } catch (e) {
    dispatch({
      type: FORGOT_PASSWORD_FAILURE,
    });
    throw e;
  }
};

export const RESET_PASSWORD_REQUEST = 'USER@RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'USER@RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'USER@RESET_PASSWORD_FAILURE';
export const reset = values => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: FORGOT_PASSWORD_REQUEST,
  });
  try {
    const { email } = await http.post(`/auth/reset`, values);
    dispatch({
      type: FORGOT_PASSWORD_SUCCESS,
      payload: { data: email },
    });
  } catch (e) {
    dispatch({
      type: FORGOT_PASSWORD_FAILURE,
    });
    throw e;
  }
};

export const DELETE_USER_REQUEST = 'USER@DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'USER@DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'USER@DELETE_USER_FAILURE';

export const deleteUser = id => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: DELETE_USER_REQUEST,
  });
  try {
    const { data } = await http.delete(`/users/${id}`);
    dispatch({
      type: DELETE_USER_SUCCESS,
      payload: {
        userId: id,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: DELETE_SERVICE_FAILURE,
    });
    throw e;
  }
};

export const RESTORE_USER_REQUEST = 'USER@RESTORE_USER_REQUEST';
export const RESTORE_USER_SUCCESS = 'USER@RESTORE_USER_SUCCESS';
export const RESTORE_USER_FAILURE = 'USER@RESTORE_USER_FAILURE';

export const restoreUser = id => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: RESTORE_USER_REQUEST,
  });
  try {
    const { data } = await http.put(`/users/${id}/restore`);
    dispatch({
      type: RESTORE_USER_SUCCESS,
      payload: {
        profile: data,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: RESTORE_USER_FAILURE,
    });
    throw e;
  }
};
