import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import Avatar from '@material-ui/core/Avatar';
// import Link from '@material-ui/core/Link';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import NotificationsIcon from '@material-ui/icons/Notifications';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from 'react-router-dom';
import {
  getUserAuthenticated,
  getUserDetails,
  isUserAdmin,
} from 'selectors/user';
import { logout } from 'actions/user';
import { openModal } from 'actions/modal';
import { SOCIAL_LOGIN_MODAL_ID } from 'consts/modalIds';
import UserAvatar from 'components/Users/UserAvatar';
import { CircularProgress } from '@material-ui/core';
import logo from 'img/logo-menu.svg';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    // flexGrow: 1,
    color: theme.palette.primary.text,
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  link: {
    textDecoration: 'none',
  },
  logo: {
    width: 40,
    marginRight: theme.spacing(1),
  },
}));

const Header = ({
  isAuthenticated,
  openModal,
  logout,
  userDetails,
  isAdmin,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);

  const avatarClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const requestsMenuClick = event1 => {
    setAnchorEl1(event1.currentTarget);
  };

  const avatarMenuClose = () => {
    setAnchorEl(null);
  };

  const menuCloseRequests = () => {
    setAnchorEl1(null);
  };

  const openLoginModal = () => {
    openModal(SOCIAL_LOGIN_MODAL_ID);
  };

  const profileClicked = () => {
    history.push('/profile');
  };

  const myRequestsClicked = () => {
    history.push('/myRequests');
  };

  const requestFromOthersClicked = () => {
    history.push('/otherRequests');
  };

  const disputedRequestsClicked = () => {
    history.push('/disputedRequests');
  };

  const logoutPressed = async () => {
    avatarMenuClose();
    await logout();
  };

  const { userData, isFetching } = userDetails;

  return (
    <AppBar position="static">
      <Container>
        <Toolbar disableGutters={true}>
          <Link to="/">
            <img src={logo} className={classes.logo} />
          </Link>
          <Link to="/services" className={classes.link}>
            <Button className={classes.title}>Часови</Button>
          </Link>
          <Link to="/users" className={classes.link}>
            <Button className={classes.title}>Корисници</Button>
          </Link>
          {!isFetching && (
            <>
              {isAuthenticated && (
                <>
                  <Button
                    className={`${classes.title} ${classes.link}`}
                    aria-controls="simple-menu1"
                    aria-haspopup="true"
                    onClick={requestsMenuClick}
                  >
                    Барања
                  </Button>
                  <Menu
                    id="simple-menu1"
                    anchorEl={anchorEl1}
                    keepMounted
                    open={Boolean(anchorEl1)}
                    onClose={menuCloseRequests}
                  >
                    <MenuItem
                      onClick={() => {
                        myRequestsClicked();
                        menuCloseRequests();
                      }}
                    >
                      Mои барања
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        requestFromOthersClicked();
                        menuCloseRequests();
                      }}
                    >
                      Барања до мене
                    </MenuItem>
                    {isAdmin && (
                      <MenuItem
                        onClick={() => {
                          disputedRequestsClicked();
                          menuCloseRequests();
                        }}
                      >
                        Спорни
                      </MenuItem>
                    )}
                  </Menu>
                </>
              )}
            </>
          )}
          <Link to="/blogs" className={classes.link}>
            <Button className={classes.title}>Успешни приказни</Button>
          </Link>
          <div className={classes.grow} />
          {isFetching && <CircularProgress color="secondary" />}
          {!isFetching && (
            <>
              {!isAuthenticated && (
                <Button className={classes.title} onClick={openLoginModal}>
                  Најава
                </Button>
              )}
              {isAuthenticated && (
                <>
                  {/* <IconButton
                aria-label="show 17 new notifications"
                color="inherit"
              >
                <Badge badgeContent={17} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton> */}
                  <UserAvatar
                    user={userData}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={avatarClick}
                    alt="User avatar"
                  />
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={avatarMenuClose}
                  >
                    <MenuItem
                      onClick={() => {
                        profileClicked();
                        avatarMenuClose();
                      }}
                    >
                      Мој профил
                    </MenuItem>
                    <MenuItem onClick={logoutPressed}>Одјави се</MenuItem>
                  </Menu>
                </>
              )}
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: getUserAuthenticated(state),
  isAdmin: isUserAdmin(state),
  userDetails: getUserDetails(state),
});

const mapDispatchToProps = {
  openModal,
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
