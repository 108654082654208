import getAxiosInstance from 'config/http';

export const LIST_REQUEST = 'CATEGORY@LIST_REQUEST';
export const LIST_SUCCESS = 'CATEGORY@LIST_SUCCESS';
export const LIST_FAILURE = 'CATEGORY@LIST_FAILURE';

export const listCategories = async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_REQUEST,
  });
  try {
    const categoryData = await http.get('/categories', {
      params: {
        limit: 10000,
      },
    });
    dispatch({
      type: LIST_SUCCESS,
      payload: {
        listData: categoryData.data,
      },
    });
  } catch (e) {
    dispatch({
      type: LIST_FAILURE,
    });
  }
};

export const STATS_REQUEST = 'CATEGORY@STATS_REQUEST';
export const STATS_SUCCESS = 'CATEGORY@STATS_SUCCESS';
export const STATS_FAILURE = 'CATEGORY@STATS_FAILURE';

export const listStats = async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: STATS_REQUEST,
  });
  try {
    const categoryData = await http.get('/categories/stats', {
      params: {
        limit: 10000,
      },
    });
    dispatch({
      type: STATS_SUCCESS,
      payload: {
        statsData: categoryData.data,
      },
    });
  } catch (e) {
    dispatch({
      type: STATS_FAILURE,
    });
  }
};
