import * as CategoryActions from 'actions/category';
import reducerWithActionMap from '../utils/reducerWithActionMap';

const initialState = {
  isAuthenticated: false,
  categoryList: {
    isFetching: false,
    isFetched: false,
    listData: null,
    mappedCategories: {},
    parentedCategoryIds: [],
  },
  categoryStats: {
    isFetching: false,
    isFetched: false,
    statsData: [],
    mappedStats: {},
  },
};

const setCategoryDataFetching = fetching => state => ({
  ...state,
  categoryList: {
    ...state.categoryList,
    isFetching: fetching,
  },
});

const setCategoryData = (state, { payload: { listData } }) => {
  const mappedCategories = listData.rows.reduce((acc, val) => {
    acc[val.id] = val;
    return acc;
  }, {});

  const filteredCategories = listData.rows.filter(val => {
    return val.ParentId;
  });
  filteredCategories.sort((a, b) => a.ParentId - b.ParentId);
  const parentedCategoryIds = filteredCategories.map(cat => cat.id);

  return {
    ...state,
    categoryList: {
      ...state.categoryList,
      listData,
      mappedCategories,
      parentedCategoryIds,
      isFetching: false,
      isFetched: true,
    },
  };
};

// Stats

const setStatsDataFetching = fetching => state => ({
  ...state,
  categoryStats: {
    ...state.categoryStats,
    isFetching: fetching,
  },
});

const setStatsData = (state, { payload: { statsData } }) => {
  const mappedStats = statsData.reduce((acc, val) => {
    acc[val.id] = val;
    return acc;
  }, {});

  const filteredStats = statsData
    .filter(val => {
      return !val.ParentId;
    })
    .map(parent => {
      // let sum = 0;
      const childCategories = [];
      for (let i = 0; i < statsData.length; ++i) {
        if (statsData[i].ParentId === parent.id) {
          // sum += statsData[i].ServicesCount;
          childCategories.push(statsData[i].id);
        }
      }
      return {
        ...parent,
        // ServicesCount: sum,
        childCategories,
      };
    });

  return {
    ...state,
    categoryStats: {
      ...state.categoryStats,
      filteredStats,
      mappedStats,
      isFetching: false,
      isFetched: true,
    },
  };
};

const actionMap = {
  [CategoryActions.LIST_REQUEST]: setCategoryDataFetching(true),
  [CategoryActions.LIST_FAILURE]: setCategoryDataFetching(false),
  [CategoryActions.LIST_SUCCESS]: setCategoryData,
  [CategoryActions.STATS_REQUEST]: setStatsDataFetching(true),
  [CategoryActions.STATS_FAILURE]: setStatsDataFetching(false),
  [CategoryActions.STATS_SUCCESS]: setStatsData,
};

export default reducerWithActionMap(actionMap, initialState);
