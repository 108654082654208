import * as UserActions from 'actions/user';
import reducerWithActionMap from '../utils/reducerWithActionMap';

const initialState = {
  isAuthenticated: false,
  userDetails: {
    isFetching: true,
    isFetched: false,
    userData: null,
  },
  userList: {
    isFetching: false,
    isFetched: false,
    listData: null,
  },
  profileData: {
    isFetching: false,
    isFetched: false,
    profile: null,
  },
};

// SELF USER DATA

const setUserDataFetching = fetching => state => ({
  ...state,
  userDetails: {
    ...state.userDetails,
    isFetching: fetching,
  },
});

const setUserData = (state, { payload: { userData } }) => ({
  ...state,
  isAuthenticated: true,
  userDetails: {
    ...state.userDetails,
    userData,
    isFetching: false,
    isFetched: true,
  },
});

const clearUserData = state => ({
  ...state,
  isAuthenticated: false,
  userDetails: {
    userData: null,
    isFetching: false,
    isFetched: false,
  },
});

// PROFILE USER DATA

const setProfileDataFetching = fetching => state => ({
  ...state,
  profileData: {
    ...state.profileData,
    isFetching: fetching,
  },
});

const setProfileData = (state, { payload: { profile } }) => ({
  ...state,
  profileData: {
    ...state.profileData,
    profile,
    isFetching: false,
    isFetched: true,
  },
});

// LIST USER DATA

const setUserListFetching = fetching => state => ({
  ...state,
  userList: {
    ...state.userList,
    isFetching: fetching,
  },
});

const setUserList = (state, { payload: { listData } }) => ({
  ...state,
  userList: {
    ...state.userList,
    listData,
    isFetching: false,
    isFetched: true,
  },
});

const actionMap = {
  // Self user data
  [UserActions.LOGIN_REQUEST]: setUserDataFetching(true),
  [UserActions.LOGIN_FAILURE]: setUserDataFetching(false),
  [UserActions.LOGIN_SUCCESS]: setUserData,
  [UserActions.EDIT_PROFILE_REQUEST]: setUserDataFetching(true),
  [UserActions.EDIT_PROFILE_FAILURE]: setUserDataFetching(false),
  [UserActions.EDIT_PROFILE_SUCCESS]: setUserData,
  [UserActions.FETCH_USER_DATA_REQUEST]: setUserDataFetching(true),
  [UserActions.FETCH_USER_DATA_FAILURE]: setUserDataFetching(false),
  [UserActions.FETCH_USER_DATA_SUCCESS]: setUserData,
  [UserActions.LOGOUT_REQUEST]: setUserDataFetching(true),
  [UserActions.LOGOUT_FAILURE]: setUserDataFetching(false),
  [UserActions.LOGOUT_SUCCESS]: clearUserData,

  // Profile actions
  [UserActions.GET_USER_PROFILE_REQUEST]: setProfileDataFetching(true),
  [UserActions.GET_USER_PROFILE_FAILURE]: setProfileDataFetching(false),
  [UserActions.GET_USER_PROFILE_SUCCESS]: setProfileData,

  // User list
  [UserActions.LIST_REQUEST]: setUserListFetching(true),
  [UserActions.LIST_FAILURE]: setUserListFetching(false),
  [UserActions.LIST_SUCCESS]: setUserList,
};

export default reducerWithActionMap(actionMap, initialState);
