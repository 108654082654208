import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Button, Typography, DialogActions } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogTitle from 'components/Dialogs/DialogTitle';
import ConnectedDialog from 'components/ConnectedDialog';
import TextField from 'components/Inputs/TextField';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FORGOT_PASSWORD_MODAL_ID } from 'consts/modalIds';
import { closeModal } from 'actions/modal';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';

import { forgot } from 'actions/user';

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('Невалидна е-маил адреса')
    .required('Ова поле е задолжително'),
});

const useStyles = makeStyles(theme => ({
  formDimensions: {
    width: 500,
  },
}));

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}))(MuiDialogContent);

const ForgotPasswordModal = ({ forgot, closeModal }) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const closeForgotPasswordDialog = () => {
    closeModal(FORGOT_PASSWORD_MODAL_ID);
  };

  const submitForgotPassword = async values => {
    values.email = values.email.trim();
    try {
      const user = await forgot(values);
      enqueueSnackbar('Барањето е успешно испратено', {
        variant: 'success',
      });
      closeForgotPasswordDialog();
    } catch (e) {
      enqueueSnackbar('Барањето е неуспешно.', {
        variant: 'error',
      });
    }
  };

  return (
    <ConnectedDialog
      id={FORGOT_PASSWORD_MODAL_ID}
      aria-labelledby="customized-dialog-title"
      maxWidth="md"
    >
      <DialogContent>
        <DialogTitle
          id="customized-dialog-title"
          onClose={closeForgotPasswordDialog}
        ></DialogTitle>
        <Typography gutterBottom variant="h5" component="h5" color="primary">
          Барање за промена на лозинката
        </Typography>
        <Formik
          initialValues={{ email: '' }}
          validationSchema={ForgotPasswordSchema}
          validateOnChange={false}
          onSubmit={submitForgotPassword}
        >
          <Form className={classes.formDimensions}>
            <Typography gutterBottom variant="body2" component="p">
              Внеси ја твојата е-маил адреса
            </Typography>
            <TextField
              name="email"
              id="outlined-basic"
              placeholder="Внеси ја e-маил адресата"
              type="text"
              variant="outlined"
              className={classes.formDimensions}
            />
            <br />
            <DialogActions>
              <Button variant="contained" color="secondary" type="submit">
                Испрати барање за промена на лозинката
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </DialogContent>
    </ConnectedDialog>
  );
};

const mapDispatchToProps = {
  forgot,
  closeModal,
};

export default connect(null, mapDispatchToProps)(ForgotPasswordModal);
