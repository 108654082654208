import getAxiosInstance from 'config/http';

export const LIST_REQUEST = 'SERVICE@LIST_REQUEST';
export const LIST_SUCCESS = 'SERVICE@LIST_SUCCESS';
export const LIST_FAILURE = 'SERVICE@LIST_FAILURE';

export const listServices = params => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_REQUEST,
  });
  try {
    const serviceData = await http.get('/services', { params });
    dispatch({
      type: LIST_SUCCESS,
      payload: {
        listData: serviceData.data,
      },
    });
  } catch (e) {
    dispatch({
      type: LIST_FAILURE,
    });
  }
};

export const FETCH_REQUEST = 'SERVICE@FETCH_REQUEST';
export const FETCH_SUCCESS = 'SERVICE@FETCH_SUCCESS';
export const FETCH_FAILURE = 'SERVICE@FETCH_FAILURE';

export const fetchService = serviceId => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_REQUEST,
  });
  try {
    const serviceData = await http.get(`/services/${serviceId}`);
    dispatch({
      type: FETCH_SUCCESS,
      payload: {
        serviceData: serviceData.data,
      },
    });
  } catch (e) {
    dispatch({
      type: FETCH_FAILURE,
    });
  }
};

export const CREATE_SERVICE_REQUEST = 'SERVICE@CREATE_SERVICE_REQUEST';
export const CREATE_SERVICE_SUCCESS = 'SERVICE@CREATE_SERVICE_SUCCESS';
export const CREATE_SERVICE_FAILURE = 'SERVICE@CREATE_SERVICE_FAILURE';

export const createService = serviceData => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: CREATE_SERVICE_REQUEST,
  });
  try {
    const { data } = await http.post('/services', serviceData);
    dispatch({
      type: CREATE_SERVICE_SUCCESS,
      payload: {
        serviceData: data,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: CREATE_SERVICE_FAILURE,
    });
    throw e;
  }
};

export const UPDATE_SERVICE_REQUEST = 'SERVICE@UPDATE_SERVICE_REQUEST';
export const UPDATE_SERVICE_SUCCESS = 'SERVICE@UPDATE_SERVICE_SUCCESS';
export const UPDATE_SERVICE_FAILURE = 'SERVICE@UPDATE_SERVICE_FAILURE';

export const updateService = (id, serviceData) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: UPDATE_SERVICE_REQUEST,
  });
  try {
    const { data } = await http.put(`/services/${id}`, serviceData);
    dispatch({
      type: UPDATE_SERVICE_SUCCESS,
      payload: {
        serviceData: data,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: UPDATE_SERVICE_FAILURE,
    });
    throw e;
  }
};

export const DELETE_SERVICE_REQUEST = 'SERVICE@DELETE_SERVICE_REQUEST';
export const DELETE_SERVICE_SUCCESS = 'SERVICE@DELETE_SERVICE_SUCCESS';
export const DELETE_SERVICE_FAILURE = 'SERVICE@DELETE_SERVICE_FAILURE';

export const deleteService = id => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: DELETE_SERVICE_REQUEST,
  });
  try {
    const { data } = await http.delete(`/services/${id}`);
    dispatch({
      type: DELETE_SERVICE_SUCCESS,
      payload: {
        serviceId: id,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: DELETE_SERVICE_FAILURE,
    });
    throw e;
  }
};
