import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import './config/moment';
import Header from 'components/Header';
import Footer from 'components/Footer';
import EmailLoginModal from 'components/Auth/EmailLoginModal';
import { closeModal, openModal } from 'actions/modal';
import { fetchUserData } from 'actions/user';
import { getUserAuthenticated, getUserDetails } from 'selectors/user';
import SignupModal from 'components/Auth/SignupModal';
import ForgotPasswordModal from 'components/Users/ForgotPasswordModal';
import SocialMediaLoginModal from 'components/Auth/SocialMediaLoginModal';
import { CustomChat, FacebookProvider } from 'react-facebook';
import { SnackbarProvider } from 'notistack';
import { makeStyles } from '@material-ui/core';
import SEO from 'components/SEO';
import ResetPasswordModal from 'components/Users/ResetPasswordModal';
import { useLocation } from 'react-use';
import ReactGA from 'react-ga';
import { useTheme } from '@material-ui/core/styles';
import { useHistory, Redirect } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 'calc(100vh - 128px)',
  },
}));

ReactGA.initialize(process.env.RAZZLE_GA_ID);

const Layout = ({
  children,
  isAuthenticated,
  openModal,
  closeModal,
  fetchUserData,
  userDetails,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    fetchUserData();
  }, []);

  let location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location]);

  return (
    <FacebookProvider appId={process.env.RAZZLE_FACEBOOK_ID} chatSupport>
      <SnackbarProvider maxSnack={3}>
        <Helmet>
          <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
          <link rel="alternate icon" href="/favicon.ico" />
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
          <script>
            {`!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '557243501834193');
        fbq('track', 'PageView');`}
          </script>
          <noscript>
            {`<img
            height="1"
            width="1"
            style="display:none"
            src="https://www.facebook.com/tr?id=557243501834193&ev=PageView&noscript=1"
          />`}
          </noscript>
        </Helmet>
        <SEO />

        <Header />
        <div className={classes.root}>
          <CustomChat
            pageId={process.env.RAZZLE_FACEBOOK_PAGE_ID}
            themeColor={theme.palette.primary.main}
          />
          {children}
          {!isAuthenticated && (
            <>
              <EmailLoginModal closeModal={closeModal} openModal={openModal} />
              <SignupModal closeModal={closeModal} openModal={openModal} />
              <ForgotPasswordModal
                closeModal={closeModal}
                openModal={openModal}
              />
              <SocialMediaLoginModal
                closeModal={closeModal}
                openModal={openModal}
              />
              <ResetPasswordModal
                closeModal={closeModal}
                openModal={openModal}
              />
            </>
          )}
        </div>
        <Footer />
      </SnackbarProvider>
    </FacebookProvider>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: getUserAuthenticated(state),
  userDetails: getUserDetails(state),
});

const mapDispatchToProps = {
  closeModal,
  openModal,
  fetchUserData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
