import React from 'react';

import { asyncComponent } from '@jaredpalmer/after';

export default [
  {
    path: '/',
    exact: true,
    component: asyncComponent({
      loader: () => import('pages/LandingPage'), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },
  {
    path: '/services',
    exact: true,
    component: asyncComponent({
      loader: () => import('pages/Services/ServiceListPage'), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },
  {
    path: '/about',
    exact: true,
    component: asyncComponent({
      loader: () => import('pages/About'), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },
  {
    path:'/verify',
    exact: true,
    component: asyncComponent({
      loader: () => import('pages/Users/Verify'), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },
  {
    path:'/reset',
    exact: true,
    component: asyncComponent({
      loader: () => import('pages/Users/ResetPassword'), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },
  {
    path:'/users',
    exact: true,
    component: asyncComponent({
      loader: () => import('pages/Users/UserListPage'), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },
  {
    path:'/myRequests',
    exact: true,
    component: asyncComponent({
      loader: () => import('pages/Requests/MyRequestListPage'), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },
  {
    path:'/otherRequests',
    exact: true,
    component: asyncComponent({
      loader: () => import('pages/Requests/OtherRequestListPage'), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },
  {
    path:'/disputedRequests',
    exact: true,
    component: asyncComponent({
      loader: () => import('pages/Requests/DisputedRequestListPage'), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },
  {
    path:'/serviceRequests/:id',
    exact: true,
    component: asyncComponent({
      loader: () => import('pages/Requests/ServiceRequestDetails'), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },
  {
    path:'/users/:id',
    exact: true,
    component: asyncComponent({
      loader: () => import('pages/Users/UserProfile'), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },
  {
    path:'/privacyPolicy',
    exact: true,
    component: asyncComponent({
      loader: () => import('pages/PrivacyPolicy'), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },
  {
    path:'/userManual',
    exact: true,
    component: asyncComponent({
      loader: () => import('pages/UserManual'), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },
  {
    path: '/editProfile',
    exact: true,
    component: asyncComponent({
      loader: () => import('pages/Users/EditProfile'), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },
  {
    path:'/profile',
    exact: true,
    component: asyncComponent({
      loader: () => import('pages/Users/MyProfile'), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },
  {
    path: '/addService',
    exact: true,
    component: asyncComponent({
      loader: () => import('pages/Services/AddService'), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },
  {
    path: '/services',
    exact: true,
    component: asyncComponent({
      loader: () => import('pages/Services/ServiceListPage'), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },
  {
    path: '/services/:id/edit',
    exact: true,
    component: asyncComponent({
      loader: () => import('pages/Services/AddService'), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },
  {
    path: '/services/:id',
    exact: true,
    component: asyncComponent({
      loader: () => import('pages/Services/ServiceDetails'), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },
  {
    path: '/blogs',
    exact: true,
    component: asyncComponent({
      loader: () => import('pages/Blogs/BlogListPage'), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },
  {
    path: '/blogs/:id',
    exact: true,
    component: asyncComponent({
      loader: () => import('pages/Blogs/BlogDetails'), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
  },
];
