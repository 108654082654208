import React from 'react';
import { Avatar } from '@material-ui/core';
import qs from 'qs';

const UserAvatar = ({ user, fullSize, ...props }) => {
  let avatarUrl = '';

  if (user) {
    if (
      user.providerData &&
      user.providerData.photos &&
      user.providerData.photos.length
    ) {
      avatarUrl = user.providerData.photos[0].value;
      
      const parsedUrl = new URL(avatarUrl);
      const fbMatcher = /.+\.facebook.com/g
      if(fbMatcher.test(parsedUrl.hostname)) {
        parsedUrl.searchParams.set('access_token', `${process.env.RAZZLE_FACEBOOK_PAGE_ID}|${process.env.RAZZLE_FACEBOOK_CLIENT_TOKEN}`);
      }
    }
    if (user.ProfilePicture) {
      if (fullSize) {
        avatarUrl = user.ProfilePicture.preview;
      } else {
        avatarUrl = user.ProfilePicture.filename;
      }
      avatarUrl = `${process.env.RAZZLE_BASE_URL}/uploads/${avatarUrl}`;
    }
  }

  return <Avatar src={avatarUrl} {...props} />;
};

export default UserAvatar;
