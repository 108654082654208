import _get from 'lodash/get';

export const getUserAuthenticated = state => state.user.isAuthenticated;
export const getUserDetails = state => state.user.userDetails;
export const getProfileData = state => state.user.profileData;
export const getUserList = state => state.user.userList;
export const isUserAdmin = state => {
  const userDetails = getUserDetails(state);
  if ((_get(userDetails, 'userData.roles') || []).indexOf('ADMIN') !== -1) {
    return true;
  }
  return false;
};
