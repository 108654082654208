import _remove from 'lodash/remove';
import * as ServiceActions from 'actions/service';
import reducerWithActionMap from '../utils/reducerWithActionMap';

const initialState = {
  isAuthenticated: false,
  serviceList: {
    isFetching: false,
    isFetched: false,
    listData: null,
  },
  serviceDetails: {
    isFetching: false,
    isFetched: false,
    serviceData: null,
  },
};

const setServiceListFetching = fetching => state => ({
  ...state,
  serviceList: {
    ...state.serviceList,
    isFetching: fetching,
  },
});

const setServiceList = (state, { payload: { listData } }) => ({
  ...state,
  serviceList: {
    ...state.serviceList,
    listData,
    isFetching: false,
    isFetched: true,
  },
});

const setServiceFetching = fetching => state => ({
  ...state,
  serviceDetails: {
    ...state.serviceDetails,
    isFetching: fetching,
  },
});

const setService = (state, { payload: { serviceData } }) => ({
  ...state,
  serviceDetails: {
    ...state.serviceDetails,
    serviceData,
    isFetching: false,
    isFetched: true,
  },
});

const setServiceDeleted = (state, { payload: { serviceId } }) => {
  const newListData = {
    count: state.serviceList.listData.count - 1,
    rows: state.serviceList.listData.rows,
  };
  _remove(newListData.rows, { id: serviceId });

  const newState = {
    ...state,
    serviceList: {
      ...state.serviceList,
      listData: newListData,
    },
  };
  return newState;
};

const actionMap = {
  [ServiceActions.LIST_REQUEST]: setServiceListFetching(true),
  [ServiceActions.LIST_FAILURE]: setServiceListFetching(false),
  [ServiceActions.LIST_SUCCESS]: setServiceList,
  [ServiceActions.FETCH_REQUEST]: setServiceFetching(true),
  [ServiceActions.FETCH_FAILURE]: setServiceFetching(false),
  [ServiceActions.FETCH_SUCCESS]: setService,
  [ServiceActions.DELETE_SERVICE_SUCCESS]: setServiceDeleted,
};

export default reducerWithActionMap(actionMap, initialState);
