import { combineReducers } from 'redux';
import user from './user';
import category from './category';
import modal from './modal';
import service from './service';
import serviceRequest from './serviceRequest';
import blog from './blog';

const rootReducer = combineReducers({
  user,
  category,
  modal,
  service,
  serviceRequest,
  blog,
});

export default rootReducer;
