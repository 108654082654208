import getAxiosInstance from 'config/http';

export const LIST_REQUEST = 'SERVICEREQ@LIST_REQUEST';
export const LIST_SUCCESS = 'SERVICEREQ@LIST_SUCCESS';
export const LIST_FAILURE = 'SERVICEREQ@LIST_FAILURE';

export const listServiceRequests = params => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_REQUEST,
  });
  try {
    const serviceRequestsData = await http.get('/service-requests', { params });
    dispatch({
      type: LIST_SUCCESS,
      payload: {
        listData: serviceRequestsData.data,
      },
    });
  } catch (e) {
    dispatch({
      type: LIST_FAILURE,
    });
    throw e;
  }
};

export const CREATE_REQUEST = 'SERVICEREQ@CREATE_REQUEST';
export const CREATE_SUCCESS = 'SERVICEREQ@CREATE_SUCCESS';
export const CREATE_FAILURE = 'SERVICEREQ@CREATE_FAILURE';

export const createServiceRequest = serviceRequestData => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: CREATE_REQUEST,
  });
  try {
    const { data } = await http.post('/service-requests', serviceRequestData);
    dispatch({
      type: CREATE_SUCCESS,
      payload: {
        serviceRequestData: data,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: CREATE_FAILURE,
    });
    throw e;
  }
};

export const FETCH_REQUEST = 'SERVICEREQ@FETCH_REQUEST';
export const FETCH_SUCCESS = 'SERVICEREQ@FETCH_SUCCESS';
export const FETCH_FAILURE = 'SERVICEREQ@FETCH_FAILURE';

export const fetchServiceRequest = serviceRequestId => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_REQUEST,
  });
  try {
    const serviceRequestData = await http.get(
      `/service-requests/${serviceRequestId}`
    );
    dispatch({
      type: FETCH_SUCCESS,
      payload: {
        serviceRequestData: serviceRequestData.data,
      },
    });
  } catch (e) {
    dispatch({
      type: FETCH_FAILURE,
    });
    throw e;
  }
};

export const UPDATE_REQUEST = 'SERVICEREQ@UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'SERVICEREQ@UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'SERVICEREQ@UPDATE_FAILURE';

export const updateServiceRequest = (
  serviceRequestId,
  data
) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: UPDATE_REQUEST,
  });
  try {
    const serviceRequestData = await http.put(
      `/service-requests/${serviceRequestId}`,
      data
    );
    dispatch({
      type: UPDATE_SUCCESS,
      payload: {
        serviceRequestData: serviceRequestData.data,
      },
    });
  } catch (e) {
    dispatch({
      type: UPDATE_FAILURE,
    });
    throw e;
  }
};

export const DELETE_REQUEST = 'SERVICEREQ@DELETE_REQUEST';
export const DELETE_SUCCESS = 'SERVICEREQ@DELETE_SUCCESS';
export const DELETE_FAILURE = 'SERVICEREQ@DELETE_FAILURE';

export const deleteServiceRequest = (
  serviceRequestId,
  data
) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: DELETE_REQUEST,
  });
  try {
    const serviceRequestData = await http.delete(
      `/service-requests/${serviceRequestId}`,
      data
    );
    dispatch({
      type: DELETE_SUCCESS,
      payload: {
        serviceRequestData: serviceRequestData.data,
      },
    });
  } catch (e) {
    dispatch({
      type: DELETE_FAILURE,
    });
    throw e;
  }
};

export const FETCH_COMMENTS_REQUEST = 'SERVICEREQ@FETCH_COMMENTS_REQUEST';
export const FETCH_COMMENTS_SUCCESS = 'SERVICEREQ@FETCH_COMMENTS_SUCCESS';
export const FETCH_COMMENTS_FAILURE = 'SERVICEREQ@FETCH_COMMENTS_FAILURE';

export const fetchServiceRequestComments = serviceRequestId => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_COMMENTS_REQUEST,
  });
  try {
    const commentsRequestData = await http.get(
      `/service-requests/${serviceRequestId}/comments`
    );
    dispatch({
      type: FETCH_COMMENTS_SUCCESS,
      payload: {
        listData: commentsRequestData.data,
      },
    });
  } catch (e) {
    dispatch({
      type: FETCH_COMMENTS_FAILURE,
    });
    throw e;
  }
};

export const CREATE_COMMENT_REQUEST = 'SERVICEREQ@CREATE_COMMENT_REQUEST';
export const CREATE_COMMENT_SUCCESS = 'SERVICEREQ@CREATE_COMMENT_SUCCESS';
export const CREATE_COMMENT_FAILURE = 'SERVICEREQ@CREATE_COMMENT_FAILURE';

export const createServiceRequestComment = (
  serviceRequestId,
  commentData
) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: CREATE_COMMENT_REQUEST,
  });
  try {
    const commentsRequestData = await http.post(
      `/service-requests/${serviceRequestId}/comments`,
      commentData
    );
    dispatch({
      type: CREATE_COMMENT_SUCCESS,
      payload: {
        commentData: commentsRequestData.data,
      },
    });
  } catch (e) {
    dispatch({
      type: CREATE_COMMENT_FAILURE,
    });
    throw e;
  }
};
