import axios from 'axios';

export const LIST_REQUEST = 'BLOG@LIST_REQUEST';
export const LIST_SUCCESS = 'BLOG@LIST_SUCCESS';
export const LIST_FAILURE = 'BLOG@LIST_FAILURE';

export const listBlogs = async dispatch => {
  dispatch({
    type: LIST_REQUEST,
  });
  try {
    const blogData = await axios.get(`${process.env.RAZZLE_STRAPI_URL}/blog-posts`);
    dispatch({
      type: LIST_SUCCESS,
      payload: {
        listData: blogData.data,
      },
    });
  } catch (e) {
    dispatch({
      type: LIST_FAILURE,
    });
  }
};

export const FETCH_REQUEST = 'BLOG@FETCH_REQUEST';
export const FETCH_SUCCESS = 'BLOG@FETCH_SUCCESS';
export const FETCH_FAILURE = 'BLOG@FETCH_FAILURE';

export const fetchBlog = blogId => async dispatch => {
  dispatch({
    type: FETCH_REQUEST,
  });
  try {
    const blogData = await axios.get(`${process.env.RAZZLE_STRAPI_URL}/blog-posts/${blogId}`);
    dispatch({
      type: FETCH_SUCCESS,
      payload: {
        blogData: blogData.data,
      },
    });
  } catch (e) {
    dispatch({
      type: FETCH_FAILURE,
    });
  }
};
