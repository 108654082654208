import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import Avatar from '@material-ui/core/Avatar';
// import Link from '@material-ui/core/Link';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import NotificationsIcon from '@material-ui/icons/Notifications';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from 'react-router-dom';
import { getUserAuthenticated, getUserDetails } from 'selectors/user';
import { logout } from 'actions/user';
import { openModal } from 'actions/modal';
import { SOCIAL_LOGIN_MODAL_ID } from 'consts/modalIds';
import UserAvatar from 'components/Users/UserAvatar';
import { CircularProgress, Grid } from '@material-ui/core';
import youthLogo from 'img/youth_can_logo.png';
import fitrLogo from 'img/fitr.svg';
import facebookLogo from 'img/facebook.svg';
import instagramLogo from 'img/instagram.svg';
import webisteLogo from 'img/website.svg';

const useStyles = makeStyles(theme => ({
  root: {
    bottom: 0,
    top: 'auto',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    // flexGrow: 1,
    color: theme.palette.primary.text,
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  link: {
    color: theme.palette.primary.text,
    textDecoration: 'none',
    marginRight: theme.spacing(2)
  },
  logosContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
  },
  youthLogo:{
    width: 100,
  },
  fitrLogo:{
    width: 200,
  },
  socialMediaIcon: {
    width: 40,
    marginRight: theme.spacing(5),
    marginTop: theme.spacing(1),
  }
}));

const Footer = ({}) => {
  const classes = useStyles();
  return (
    <>
    <AppBar position="relative" color="secondary" className={classes.root}>
      <Container>
        <Grid container>
          <Grid item xs={12} md={9}>
            <Toolbar disableGutters={false}>
            <Link to="/about" className={classes.link}>
              За платформата
            </Link>
            <Link to="/privacyPolicy" className={classes.link}>
              Услови на користење
            </Link>
            <Link to="/userManual" className={`${classes.link}`}>
              Насоки за користење
            </Link>
          </Toolbar>
          </Grid>
          <Grid item xs={12} md={3}>
            <div>
              <a href="https://www.facebook.com/mladite.mozat/" target="_blank">
                <img src={facebookLogo} className={classes.socialMediaIcon}/>
              </a>
              <a href="https://www.instagram.com/youth_can/?hl=en&fbclid=IwAR2H4OFtEJN1p3mWPN0CgfvMJkUepkCKO2x3rXiqBCpHBU8QLkhLoTECeG8" target="_blank">
                <img src={instagramLogo} className={classes.socialMediaIcon} />
              </a>
              {/* <a href="https://youthcan.org.mk/" target="_blank">
                <img src={facebookLogo} className={classes.socialMediaIcon}/>
              </a> */}
            </div>
          </Grid>
        </Grid>
      </Container>
    </AppBar>
    <AppBar position="relative" color="default" className={classes.root}>
      <Container>
        <div className={classes.logosContainer}>
          <a href="https://youthcan.org.mk/" target="_blank"><img src={youthLogo} className={classes.youthLogo} /></a>
          <a href="https://fitr.mk/" target="_blank"><img src={fitrLogo} className={classes.fitrLogo} /></a>
        </div>
      </Container>
    </AppBar>
    </>
  );
};
export default Footer;
