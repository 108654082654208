import React from 'react';

import {
  FormHelperText,
  FormControl,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { useField } from 'formik';

export default props => {
  const [field, meta, helpers] = useField(props);
  const { topLabel, checkboxLabel, formControlProps } = props;

  return (
    <FormControl error={meta.touched && meta.error} {...formControlProps}>
      {topLabel && <FormLabel component="legend">{topLabel}</FormLabel>}
      <FormControlLabel
        control={<Checkbox {...field} {...props} />}
        label={checkboxLabel || ' '}
      />
      <FormHelperText>{meta.error || ' '}</FormHelperText>
    </FormControl>
  );
};
