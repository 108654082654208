import { createMuiTheme } from '@material-ui/core/styles';
import { indigo, orange, cyan } from '@material-ui/core/colors';
import OpenSans from './fonts/OpenSans-Regular.ttf';
import OpenSansBold from './fonts/OpenSans-Bold.ttf';

const openSans = {
  fontFamily: 'OpenSans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    url(${OpenSans})
  `,
  // unicodeRange:
  //   'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
const openSansBold = {
  fontFamily: 'OpenSans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'bold',
  src: `
    url(${OpenSansBold})
  `,
  // unicodeRange:
  //   'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

// Configure Material UI theme
const theme = createMuiTheme({
  palette: {
    primary: {
      main:'#3E2288',
      text: '#FFF',
    },
    secondary: {
      main: '#7950F5',
    },
    facebook:{
      main: '#4267B2',
      contrastText: '#FFF',
      dark: '#34528d',
    },
    google:{
      main: '#4285F4',
      contrastText: '#FFF',
      dark: '#0c51c0',
    },
    third: {
      main: '#FFF',
    },
    accent: {
      main: "#ffa800",
      text: "#FFF",
    },
  },
  type: 'light',
  typography: {
    fontFamily: 'OpenSans',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [openSans, openSansBold],
      },
    },
  },
});

export default theme;
