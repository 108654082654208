import React, { Children } from 'react';
import { connect } from 'react-redux';
import { closeModal } from 'actions/modal';
import { isModalOpen } from 'selectors/modal';
import { Dialog } from '@material-ui/core';

const ConnectedDialog = ({ id, isOpen, closeDialog, onClose, ...rest }) => {
  return (
    <Dialog
      onClose={() => {
        closeDialog(id);
      }}
      open={isOpen || false}
      {...rest}
    />
  );
};

const mapStateToProps = (state, { id }) => ({
  isOpen: isModalOpen(state, id),
});

const mapDispatchToProps = {
  closeDialog: closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedDialog);
