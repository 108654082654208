import React from 'react';
import Helmet from 'react-helmet';

import defaultImg from 'img/og-image.png';

const SEO = ({
  title = 'Час за час',
  description = 'Нова дигитална платформа која ти овозможува да се поврзеш со заедницата на поинаков начин и квалитетно да го исполнуваш своето слободно време',
  ogImage = `${process.env.RAZZLE_BASE_URL}${defaultImg}`,
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:type" content="website" />
    </Helmet>
  );
};
export default SEO;
