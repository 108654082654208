import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Button, Typography, DialogActions } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogTitle from 'components/Dialogs/DialogTitle';
import ConnectedDialog from 'components/ConnectedDialog';
import TextField from 'components/Inputs/TextField';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { RESET_PASSWORD_MODAL_ID } from 'consts/modalIds';
import { closeModal } from 'actions/modal';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';

import { reset } from 'actions/user';
import { getModalData } from 'selectors/modal';

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(5, 'Внесете најмалку 5 знаци')
    .required('Ова поле е задолжително'),
  passwordRepeat: Yup.string()
    .oneOf([Yup.ref('password')], 'Лозинките не се исти')
    .required('Ова поле е задолжително'),
});

const useStyles = makeStyles(theme => ({
  formDimensions: {
    width: 500,
  },
}));

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}))(MuiDialogContent);

const ResetPasswordModal = ({ reset, closeModal, modalData }) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  let token = null;
  if (modalData) {
    token = modalData.token;
  }

  const closeForgotPasswordDialog = () => {
    closeModal(RESET_PASSWORD_MODAL_ID);
  };

  const resetPassword = async values => {
    try {
      const user = await reset(values);
      enqueueSnackbar('Лозинката е успешно променета', {
        variant: 'success',
      });
      closeForgotPasswordDialog();
    } catch (e) {
      enqueueSnackbar('Барањето е неуспешно.', {
        variant: 'error',
      });
    }
  };

  return (
    <ConnectedDialog
      id={RESET_PASSWORD_MODAL_ID}
      aria-labelledby="customized-dialog-title"
      maxWidth="md"
    >
      <DialogContent>
        <DialogTitle
          id="customized-dialog-title"
          onClose={closeForgotPasswordDialog}
        ></DialogTitle>
        <Typography gutterBottom variant="h5" component="h5" color="primary">
          Промена на лозинката
        </Typography>
        <Formik
          initialValues={{
            password: '',
            passwordRepeat: '',
            forgotPasswordToken: token,
          }}
          validationSchema={resetPasswordSchema}
          validateOnChange={false}
          enableReinitialize={true}
          onSubmit={resetPassword}
        >
          <Form className={classes.formDimensions}>
            <Typography gutterBottom variant="body2" component="p">
              Внеси нова лозинка
            </Typography>
            <TextField
              name="password"
              id="outlined-basic"
              label="Внеси лозинка"
              variant="outlined"
              type="password"
              fullWidth
            />
            <br />
            <TextField
              name="passwordRepeat"
              id="outlined-basic"
              label="Потврди лозинка"
              variant="outlined"
              type="password"
              fullWidth
            />
            <br />
            <DialogActions>
              <Button variant="contained" color="secondary" type="submit">
                Промени лозинка
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </DialogContent>
    </ConnectedDialog>
  );
};

const mapDispatchToProps = {
  reset,
  closeModal,
};

const mapStateToProps = state => {
  return {
    modalData: getModalData(state, RESET_PASSWORD_MODAL_ID),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordModal);
