import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Button, Typography, Grid } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';

import { signup } from 'actions/user';

import DialogTitle from 'components/Dialogs/DialogTitle';
import TextField from 'components/Inputs/TextField';
import Checkbox from 'components/Inputs/Checkbox';
import { SIGNUP_MODAL_ID } from 'consts/modalIds';
import ConnectedDialog from 'components/ConnectedDialog';

import login_illustration from 'img/sign_in.svg';
import { useSnackbar } from 'notistack';

const signupSchema = Yup.object().shape({
  // firstName: Yup.string().required('Ова поле е задолжително'),
  // lastName: Yup.string().required('Ова поле е задолжително'),
  email: Yup.string()
    .trim()
    .email('Невалидна е-маил адреса')
    .required('Ова поле е задолжително'),
  password: Yup.string()
    .min(5, 'Внесете најмалку 5 знаци')
    .required('Ова поле е задолжително'),
  passwordRepeat: Yup.string()
    .oneOf([Yup.ref('password')], 'Лозинките не се исти')
    .required('Ова поле е задолжително'),
  privacyPolicy: Yup.bool().oneOf(
    [true],
    'Мора да се согласите со условите на платформата'
  ),
});

const useDialogStyles = makeStyles(theme => ({
  dialogImage: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
  },
}));

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      maxWidth: '100%',
      width: '500px',
    },
  },
  introTextMargin: {
    marginLeft: 10,
  },
  privacyPolicyLabel: {
    marginLeft: theme.spacing(1),
  },
}));

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const ImageBox = withStyles(() => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
  },
}))(Box);

const SignupModal = ({ signup, openModal, closeModal }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dialogStyles = useDialogStyles();
  const classes = useStyles();

  const closeSignupModal = () => {
    closeModal(SIGNUP_MODAL_ID);
  };

  const registerUser = async values => {
    values.email = values.email.trim();
    try {
      await signup(values);
      closeSignupModal();
      enqueueSnackbar(
        'Проверете го вашиот е-маил за да ја потврдите вашата сметка',
        {
          variant: 'success',
        }
      );
    } catch (e) {
      enqueueSnackbar('Неуспешна регистрација. Обидете се повторно', {
        variant: 'error',
      });
    }
  };

  return (
    <ConnectedDialog
      id={SIGNUP_MODAL_ID}
      aria-labelledby="customized-dialog-title"
      maxWidth="md"
    >
      <Grid container spacing={0}>
        <Grid item xs={12} md={4} sm={6}>
          <ImageBox p={2} bgcolor="background.default">
            <img
              src={login_illustration}
              className={dialogStyles.dialogImage}
            />
          </ImageBox>
        </Grid>
        <Grid item xs={12} md={8} sm={6}>
          <DialogContent>
            <DialogTitle
              id="customized-dialog-title"
              onClose={closeSignupModal}
            >
              Регистрација
            </DialogTitle>
            <Typography gutterBottom className={classes.introTextMargin}>
              Креирај го твојот кориснички профил и приклучи се на најголемата
              заедница наменета за разменување на услуги и вештини, наречена
              Временска банка. Регистрирај се преку неколку кратки чекори и
              понуди го своето време за добиеш кредит од еден час, којшто потоа
              ти можеш да го искористиш.
            </Typography>
            <Formik
              initialValues={{
                // firstName: '',
                // lastName: '',
                email: '',
                password: '',
                passwordRepeat: '',
                privacyPolicy: false,
              }}
              validationSchema={signupSchema}
              validateOnChange={false}
              onSubmit={registerUser}
            >
              <Form className={classes.root} autoComplete="on">
                <TextField
                  name="email"
                  id="outlined-basic"
                  label="Внеси е-маил адреса"
                  variant="outlined"
                />
                <br />
                <TextField
                  name="password"
                  id="outlined-basic"
                  label="Внеси лозинка"
                  variant="outlined"
                  type="password"
                />
                <br />
                <TextField
                  name="passwordRepeat"
                  id="outlined-basic"
                  label="Потврди лозинка"
                  variant="outlined"
                  type="password"
                />
                <br />
                <Checkbox
                  name="privacyPolicy"
                  size="small"
                  variant="outlined"
                  checkboxLabel={
                    <div className={classes.privacyPolicyLabel}>
                      Ги прочитав и се согласувам со условите за користење на
                      платформата Час за час
                    </div>
                  }
                />
                <Button variant="contained" color="secondary" type="submit">
                  Регистрирај се
                </Button>
              </Form>
            </Formik>
          </DialogContent>
        </Grid>
      </Grid>
    </ConnectedDialog>
  );
};

const mapDispatchToProps = {
  signup,
};

export default connect(null, mapDispatchToProps)(SignupModal);
